<template>
  <b-overlay :show="loading" rounded="sm">
    <b-card title="Reporte recepción de documentos">
      <div class="row mb-1">
        <div class="col d-flex justify-content-between">
          <div>
            <b-button
              variant="success"
              class="btn-icon mr-1"
              @click="getReportReceptions"
            >
              <feather-icon icon="RefreshCwIcon" size="16" />
            </b-button>
            <date-picker
              range-separator="-"
              v-model="rangePicker"
              :default-value="rangePicker"
              range
              append-to-body
              lang="es"
              format="YYYY-MM-DD"
              style="width: 200px"
              class="mr-1"
            ></date-picker>

            <b-form-input
              v-model="term"
              class="d-inline-block mr-1"
              style="width: 300px"
              placeholder="Buscar..."
            />
          </div>

          <download-excel
            class="btn btn-primary"
            :data="json_data"
            name="reporte_facturas.xls"
            :title="title_report"
            :fetch="downloadExcel"
          >
            <i class="fa fa-file-excel mr-1"></i>
            Exportar Excel
          </download-excel>
        </div>
      </div>

      <hr />

      <div class="row pb-3 pt-1 text-center">
        <div class="col">
          <div class="font-weight-bold">{{ quantity_docs }}</div>
          <div class="text-primary">Cantidad</div>
        </div>
        <div class="col">
          <div class="font-weight-bold">
            {{ currencyFormat(subtotal_amount) }}
          </div>
          <div class="text-primary">Subtotal Ventas</div>
        </div>
        <!-- <div class="col">
          <div class="font-weight-bold">
            {{ currencyFormat(discount_amount) }}
          </div>
          <div class="text-primary">Descuentos</div>
        </div> -->
        <div class="col">
          <div class="font-weight-bold">{{ currencyFormat(tax_amount) }}</div>
          <div class="text-primary">IVA</div>
        </div>
        <!-- <div class="col">
          <div class="font-weight-bold">
            {{ currencyFormat(iva_devuelto_amount) }}
          </div>
          <div class="text-primary">IVA Devuelto</div>
        </div> -->
        <div class="col">
          <div class="font-weight-bold">
            {{ currencyFormat(subtotal_amount - discount_amount + tax_amount) }}
          </div>
          <div class="text-primary">Total Neto</div>
        </div>
      </div>

      <b-table :items="report" :fields="tableColumns" striped responsive>
        <template #cell(key50digits)="data">
          {{ data.item.key50digits.substring(31, 41) }}
        </template>

        <template #cell(emision_date)="data">
          {{ dateFormat(data.item.emision_date) }}
        </template>

        <template #cell(message)="data">
          {{ JSON.parse(data.item.data).Emisor.Nombre }}
        </template>
        <template #cell(message_type)="data">
          {{ JSON.parse(data.item.data).Emisor.Identificacion.Numero }}
        </template>

        <template #cell(subtotal)="data">
          {{ currencyFormat(data.item.subtotal, data.item.currency) }}
        </template>
        <template #cell(tax)="data">
          {{ currencyFormat(data.item.tax, data.item.currency) }}
        </template>
        <template #cell(total)="data">
          {{ currencyFormat(data.item.total, data.item.currency) }}
        </template>
      </b-table>

      <p class="text-center" v-if="!report.length">
        No hay depositos realizados en este rango de fechas
      </p>
    </b-card>
  </b-overlay>
</template>

<script>
import ReportService from "@/services/report.service";
import DatePicker from "vue2-datepicker";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from "moment";

import { currencyFormat } from "@/helpers";

import {
  BOverlay,
  BCard,
  BButton,
  BTable,
  BBadge,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BFormInput
} from "bootstrap-vue";

export default {
  components: {
    VuePerfectScrollbar,
    BOverlay,
    BCard,
    DatePicker,
    BButton,
    BTable,
    BBadge,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BFormInput
  },
  data() {
    return {
      term: "",
      perfectScrollbarSettings: {
        maxScrollbarLength: 10
      },
      rangePicker: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      ],
      report: [],
      loading: false,
      tableColumns: [
        { key: "key50digits", label: "Consecutivo" },
        { key: "message", label: "FEmisor" },
        { key: "message_type", label: "Cédula" },
        { key: "emision_date", label: "Fecha" },
        { key: "subtotal", label: "Subtotal" },
        // { key: "discount", label: "Descuento" },
        { key: "tax", label: "IVA" },
        { key: "total", label: "Total" }
      ],
      json_data: [],
      loading_excel: false,
      subtotal_amount: 0,
      tax_amount: 0,
      discount_amount: 0,
      iva_devuelto_amount: 0,
      quantity_docs: 0,
      checkedDefaultOptions: ["rechazado"],
      title_report: ""
    };
  },
  watch: {
    rangePicker() {
      if (this.rangePicker.length > 1) {
        this.getReportReceptions();
      }
    },
    term(newVal) {
      this.getReportReceptions();
    }
  },
  methods: {
    async getReportReceptions(page = 1) {
      try {
        this.loading = true;
        const { data: res } = await ReportService.getReportReceptions(page, {
          rangeDates: `${moment(this.rangePicker[0]).format(
            "yyyy-MM-DD"
          )},${moment(this.rangePicker[1]).format("yyyy-MM-DD")}`,
          term: this.term
        });
        this.report = res.documents.data;

        //this.pageCount = res.data.documents.last_page;
        //this.page = res.data.documents.current_page;
        this.items = res.documents.data;
        this.subtotal_amount = res.subtotal_amount;
        this.tax_amount = res.tax_amount;
        this.discount_amount = res.discount_amount;
        this.iva_devuelto_amount = res.iva_devuelto_amount;
        this.quantity_docs = res.documents.total;

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    currencyFormat(amount, currency) {
      const symbol = currency == 1 ? "USD" : "CRC";
      return currencyFormat(amount, symbol);
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-yyyy");
    },

    async downloadExcel() {
      let search = {
        rangeDates: `${moment(this.rangePicker[0]).format(
          "yyyy-MM-DD"
        )},${moment(this.rangePicker[1]).format("yyyy-MM-DD")}`,
        term: this.term
      };
      let exist_registers = true;
      this.skip = 0;
      this.json_data = [];
      this.loading_excel = true;

      this.title_report = [
        `Tavo Mensajería`,
        "Reporte recepción de documentos",
        `Fechas: desde ${moment(this.rangePicker[0]).format(
          "DD-MM-YYYY"
        )} hasta ${moment(this.rangePicker[1]).format("DD-MM-YYYY")}`
      ];

      try {
        while (exist_registers) {
          search.skip = this.skip;
          let report_data = await this.getSkipRegisters(1, search);
          let items = report_data;

          if (items.length > 0) {
            this.skip = this.skip + 100;
          } else {
            exist_registers = false;
          }
        }
        this.loading_excel = false;

        let tax = this.tax_amount;
        let discount = this.discount_amount;
        let subtotal = this.subtotal_amount;
        let total =
          this.subtotal_amount - this.discount_amount + this.tax_amount;
        console.log(
          this.subtotal_amount,
          this.discount_amount,
          this.tax_amount
        );

        this.json_data.push({
          "": "",
          "": "",
          "": "",
          "": "",
          ND: "Totales",
          subtotal: `${subtotal}`,
          descuento: `${discount}`,
          IVA: `${tax}`,
          total: `${total}`
        });

        return this.json_data;
      } catch (error) {
        this.loading_excel = false;
        console.log(error);
      }
    },
    async getSkipRegisters(page, search) {
      try {
        let datos = await ReportService.getReportReceptionsSkip(page, search);
        let items = datos.data;
        items.forEach(i => {
          let el = {
            consecutivo: `${i.reference}`,
            cliente: JSON.parse(i.data).Emisor.Nombre,
            "cedula cliente": JSON.parse(i.data).Emisor.Identificacion.Numero,
            fecha: moment(i.emision_date).format("DD-MM-YYYY"),
            subtotal: i.subtotal,
            descuento: i.discount,
            IVA: i.tax,
            total: i.total
          };
          this.json_data.push(el);
        });
        return items;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    }
  },
  created() {
    this.getReportReceptions();
  }
};
</script>
